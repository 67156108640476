<template>
  <div class="hotel-home">
    <div class="con_nav">
      <nav-tab :nav-data="navData" />
    </div>
    <div class="con_center">
      <HomeTop />
      <HomeBottom />
    </div>
    <div class="con_bottom">
      <layout-bottom />
    </div>
  </div>
</template>

<script>
import NavTab from '@/components/NavTab'
import LayoutBottom from '@/components/LayoutBottom'
import HomeTop from '@/components/home/homeTop'
import HomeBottom from '@/components/home/homeBottom'
import { getNavData } from '@/utils/navData'
export default {
  components: {
    NavTab,
    HomeTop,
    HomeBottom,
    LayoutBottom
  },
  data() {
    return {
      navData: getNavData(0)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
