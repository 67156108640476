<template>
  <div class="home_bottom">
    <div class="bg">
      <div class="title">{{ $t('homeBottom.title') }}</div>
      <ul class="list">
        <li class="row">
          <div class="menu left">
            <div class="item">
              <img src="@/assets/images/homeMenu/item1.png" alt="">
              <div class="right">
                <p class="text1">{{ $t('homeBottom.menu1') }}</p>
                <p class="text2" style="width: 365px;height: 180px;">{{ $t('homeBottom.menuDesc1') }}</p>
              </div>
            </div>
            <div class="item-bottom">
              <img src="@/assets/images/homeMenu/line-right.png" alt="" class="line-img1">
            </div>
          </div>
          <div class="menu right">
            <div class="item item-right">
              <img src="@/assets/images/homeMenu/item2.png" alt="">
              <div class="right">
                <p class="text1">{{ $t('homeBottom.menu2') }}</p>
                <p class="text2" style="width: 307px;height: 180px;">{{ $t('homeBottom.menuDesc2') }}</p>
              </div>
            </div>
            <div class="item-bottom item-bottom-right">
              <img src="@/assets/images/homeMenu/line-left.png" alt="" class="line-img2">
            </div>
          </div>
        </li>
        <li class="row row2">
          <div class="menu left">
            <div class="item row2-item-left">
              <img src="@/assets/images/homeMenu/item3.png" alt="">
              <div class="right">
                <p class="text1">{{ $t('homeBottom.menu3') }}</p>
                <p class="text2" style="width: 358px;">{{ $t('homeBottom.menuDesc3') }}</p>
              </div>
            </div>
            <div class="item-bottom">
              <img src="@/assets/images/homeMenu/line-right.png" alt="" class="line-img3">
            </div>
          </div>
          <img src="@/assets/images/homeMenu/menu.png" alt="" class="menu-img">
          <div class="menu right">
            <div class="item item-right">
              <img src="@/assets/images/homeMenu/item4.png" alt="">
              <div class="right">
                <p class="text1">{{ $t('homeBottom.menu4') }}<br>{{ $t('homeBottom.menu4br') }}</p>
                <p class="text2" style="width: 295px;">{{ $t('homeBottom.menuDesc4') }}</p>
              </div>
            </div>
            <div class="item-bottom item-bottom-right">
              <img src="@/assets/images/homeMenu/line-left.png" alt="" class="line-img4">
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.menu-img {
  // width: 270px;
  height: 259px;
  margin-top: 89px;
}
.row2-item-left {
  padding-right: 22px;
}
.row2 {
  margin-top: 65px;
}
.row {
  display: flex;
  justify-content: space-between;
}
.item-right {
  padding-right: 0 !important;
}
.menu {
  .item {
    display: flex;
    align-items: flex-start;
    padding-right: 19px;
    &img {
      width: 96px;
      height: 96px;
    }
    .right {
      margin-left: 21px;
      // width: 363px;
      .text1{
        font-size: 22px;
        color: #333333;
        font-weight: bold;
        line-height: 28px;
      }
      .text2{
        font-size: 14px;
        color: #666666;
        line-height: 20px;
        margin-top: 20px;
        // overflow: hidden;
        word-wrap: break-word;
        word-break: break-all;
      }
    }
  }
  .item-bottom {
    display: flex;
    justify-content: flex-end;
    .line-img1 {
      margin-top: 34px;
      width: 381px;
      height: 64px;
    }
    .line-img2 {
      margin-top: 24px;
      width: 420px;
      height: 76px;
    }
    .line-img3 {
      margin-top: 39px;
      width: 373px;
      height: 49px;
    }
    .line-img4 {
      margin-top: 22px;
      width: 404px;
      height: 49px;
    }
  }
}
.item-bottom-right {
  justify-content: flex-start !important;
}
.line-left {
  width: 420px;
}
.home_bottom{
  min-width: 1200px;
  padding-top: 135px;
  padding-bottom: 150px;
  background-color: #fff;
  .bg{
    position: relative;
  }
  .title{
    text-align: center;
    margin-bottom: 90px;
    font-size: 68px;
    color: #333333;
    font-weight: bold;
  }
  .list{
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    background-color: #fff;
  }
}
</style>
